.cursor-hidden::after {
  content: ''; /* Empty content to hide the cursor */
  display: inline-block;
  margin-left: 5px;
  opacity: 0; /* Hide cursor */
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Adjust duration as needed */
}

.fade-in.visible {
  opacity: 1;
}


#home {
  background-image: url('../assets/background.jpg'); /* Updated path to your image */
  background-size: cover;
  background-position: center;
  color: white; /* Ensure the text is readable */
  padding: 100px 20px;
  height: 700px;
  width: 100%;
  box-sizing: border-box;
  display: flex; /* Enable flexbox */
  align-items: center; /* Center items vertically */
  flex-direction: column; /* Stack content vertically on smaller screens */
}

.home-content {
  text-align: left; /* Align text to the left */
  margin-left: 35px; /* Add some margin to the left */
  margin-top: 30px;
  margin-bottom: 150px;
  width: 100%; /* Ensure content takes up full width */
  max-width: 1200px; /* Add max-width for readability on larger screens */
}

.home-content h1 {
  font-weight: 700;
  font-size: 52px; /* Adjust font size for heading */
  max-width: 1000px; /* Set a max-width for better readability */
  margin-bottom: 10px; /* Add some space below the heading */
}

.home-content p {
  font-size: 18px; /* Adjust font size for paragraph */
  max-width: 600px; /* Set a max-width for better readability */
  line-height: 1.5; /* Increase line height for better readability */
}

.home-aicontent {
  text-align: left; /* Align text to the left */
  margin-top: 60px;
  margin-left: 20px; /* Add some margin to the left */
  width: 100%; /* Ensure content takes up full width */
  max-width: 800px; /* Set a max-width for better readability */
}

.home-aicontent h1 {
  font-size: 32px; /* Adjust font size for heading */
  color: #00c5d3;
  margin-bottom: 10px; /* Add some space below the heading */
}

.home-aicontent p {
  font-size: 18px; /* Adjust font size for paragraph */
  max-width: 450px; /* Set a max-width for better readability */
  line-height: 1.5; /* Increase line height for better readability */
}

.home-aicontent image {
  width: 100%;
  height: 200px;
  margin-bottom: 100px;
  max-height: 40px;
}


.cursor-white::after {
  content: '|';
  display: inline-block;
  margin-left: 5px;
  opacity: 1;
  color: white; /* White cursor color */
  animation: blink 0.7s infinite;
}

.cursor-colored::after {
  content: '|';
  display: inline-block;
  margin-left: 5px;
  opacity: 1;
  color: #00c5d3; /* Change this to your desired cursor color */
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}




/* Media queries for responsiveness */
@media (max-width: 768px) {
  #home {
    padding: 50px 20px;
    height: auto; /* Adjust height to fit content */
    align-items: flex-start; /* Align items to the start */
  }

  .home-content {
    margin-left: 20px; /* Reduce left margin on smaller screens */
    margin-bottom: 50px; /* Reduce bottom margin on smaller screens */
  }

  .home-content h1 {
    font-size: 36px; /* Adjust font size for smaller screens */
    max-width: 100%; /* Remove max-width for smaller screens */
    margin-left: -20px;
  }

  .home-content p {
    font-size: 16px; /* Adjust font size for smaller screens */
    max-width: 100%; /* Remove max-width for smaller screens */
    margin-left: -20px;
  }

  .home-aicontent {
    margin-left: 20px; /* Reduce left margin on smaller screens */
    margin-top: 40px; /* Reduce top margin on smaller screens */
  }

  .home-aicontent h1 {
    font-size: 24px; /* Adjust font size for smaller screens */
    margin-left: -40px;
  }

  .home-aicontent p {
    font-size: 16px; /* Adjust font size for smaller screens */
    max-width: 100%; /* Remove max-width for smaller screens */
    margin-left: -40px;
  }
}

@media (max-width: 400px) {
  .home-content {
    margin-left: 10px; /* Further reduce left margin on very small screens */
  }

  .home-content h1 {
    font-size: 28px; /* Further reduce font size on very small screens */
  }

  .home-content p {
    font-size: 14px; /* Further reduce font size on very small screens */
  }

  .home-aicontent h1 {
    font-size: 20px; /* Further reduce font size on very small screens */
  }

  .home-aicontent p {
    font-size: 14px; /* Further reduce font size on very small screens */
  }
}

