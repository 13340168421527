
/* #ai {
    display: flex;
    flex-direction: row;
    padding: 110px 20px;
    width: 110%;
    margin-bottom: 100px;
    background-color: #fff;
    box-sizing: border-box;

  } */
#ai {
      display: flex;
      padding: 10px 20px;
      width: 100%;
      background-color: #ffffff;
      box-sizing: border-box;
      margin-bottom: 30px;
  }



#ai1 {
      display: flex;
      flex-wrap: wrap;
      width: 155%;
      box-sizing: border-box;
      margin-bottom: 50px;
      margin-right: 20px;
      margin-left: -10px;
      margin-top: 50px;
  }
#ai2 {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 50px;
      margin-left: 20px;
      margin-right: -10px;
      margin-top: 50px;
  }


  
  /* #ai h1 {
    color: #000000;
    width: 150%;
    font-size: 40px;
    font-weight: 600;
    text-align: left;
    margin-right: 80px;
    margin-top: 40px;
  }
 */

  
  #ai1 h1 {
    color: #000000;
    width: 120%;
    font-weight: 650;
    font-size: 48px;
    text-align: left;
    margin-top: -10px;
  }
  
  #ai1 h1 span  {
    font-weight: 700; /* Bold weight for the highlighted text */
    background: linear-gradient(to top, #bbfaff 25%, transparent 0%);
    padding: 0 5px;
    color: #000; /* Ensures text color is black */
    background-size: 100% 100%;
    background-position: 0 30%; /* Adjust this value to move the highlight up or down */
    background-repeat: no-repeat;
  }



  /* #ai h2 {
    color: #000000;
    width: 100%;
    font-size: 28px;
    text-align: left;
    margin-right: 10px;
    margin-left: -650px;
    margin-top: 110px;
  } */
  

  
  #ai1 h2 {
    color: #000000;
    width: 100%;
    font-size: 28px;
    text-align: left;
    margin-top: 10px;
  }
  

  
  /* #ai p {
    color: #000000;
    width: 100%;
    font-size: 18px;
    text-align: justify;
    margin-left: 200px;
    margin-right: 150px;
    line-height: 25px;
    white-space: pre-line;
  }   */


 
  #ai2 p {
    color: #000000;
    width: 100%;
    font-size: 18px;
    text-align: justify;
    }





    /* Media queries for responsiveness */
@media (max-width: 768px) {
  #ai {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }
  #ai1, #ai2 {
    width: 100%; /* Full width for both sections */
    margin-right: 0;
    margin-left: 0;
  }
  #ai1 {
    margin-bottom: 40px; /* Adjust bottom margin for spacing */
  }
}

@media (max-width: 480px) {
  #ai1 h1 {
    font-size: 36px; /* Adjust heading font size for smaller screens */
    width: auto;
  }
  #ai1 h2 {
    font-size: 24px; /* Adjust subheading font size for smaller screens */
  }
  #ai2 p {
    font-size: 16px; /* Adjust paragraph font size for smaller screens */
  }
}