#services {
    padding: 100px 20px;
    background-color: #ffffff;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: -50px;
  }
  
.heading {
    color: #000000; /* Change this to your preferred color for headings */
    text-align: left;
    font-size: 42px;
    font-weight: 500;
    margin-left: 50px;
    margin-top: -50px;
}

.subheading {
    color: #000000; /* Change this to your preferred color for subheadings */
    line-height: 25px;
    font-size: 18px;
    margin-left: 50px;
    margin-bottom: 30px;
    white-space: pre-line;
    text-align: left;
}


.services-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-card {
  background-color: #fff;
  border: 2px solid #00c5d3;
  border-radius: 8px;
  height: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: auto;
  text-align: left;
}

/* Styling the service card heading */
.service-card h2 {
  font-size: 1.5em; /* Adjust the font size as needed */
  margin: 10px 0; /* Adjust the margin as needed */
  padding: 5px 0; /* Adjust the padding as needed */
  color: #000000; /* Adjust the color as needed */
}

/* Styling the service card paragraph */
.service-card p {
  font-size: 1em; /* Adjust the font size as needed */
  margin: 10px 0; /* Adjust the margin as needed */
  padding: 5px 0; /* Adjust the padding as needed */
  color: #000000; /* Adjust the color as needed */
  text-align: justify;
}


#uthoKaAbba {
  width: 370px;
  height: 300px;
  text-align: left;
  background-color: gray;
  position: relative;
  font-size: clamp(.7rem, 1vw, 1rem);
}




#Utho {
  height: 3.5rem;
  /* transform: all 0.8; */
  transition: all 0.8s;
}

#Utho h2 {
  font-size: clamp(.7rem, 1.5vw, 1.1rem);
}

#uthoKaAbba:hover #Utho {
  height: 16rem;
}

/* For desktops and larger devices */
@media (min-width: 1024px) {
  .service-card {
    width: calc(33.33% - 40px); /* Three columns */
  }
}

@media (main-width: 1024px) {
  #uthoKaAbba {
    width: calc(33.33% - 40px); /* Three columns */
  }

}
@media (max-width: 1024px) {
#Utho h2 {
  margin-bottom: 80px;
}
  
}


@media (max-width: 300px) {
  
.heading {
  margin-left: 10px;
}

.subheading {
  margin-left: 10px;
}
}