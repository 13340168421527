
.App-header {
  background-color: #ff000000;
  padding: 20px;
  color: white;
  position: fixed;
  width: 98.6%;
  height: 100px; /* Set a consistent height */
  z-index: 1;
}

.navbar {
  display: flex;
  justify-content: space-between; /* Changed from space-around to space-between */
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  margin-top: -20px;
  margin-right: -20px;
  margin-left: -20px;
  height: 100px; /* Set a consistent height */
  z-index: 1;
  width: 98.6%;
}

.navbar a {
  color: white;
  text-decoration: none;
  padding: 10px;
}

.logo-image img {
  height: auto;
  width: auto;
  margin-top: -10px;
  max-height: 100px; /* Adjust the max height to ensure it fits within the navbar */
  max-width: 100%; /* Ensure it scales properly */
  margin-left: 50px;
}

.search-icon {
  font-size: 20px;
  cursor: pointer;
  color: white;
  margin-right: 40px;
}

.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.search-form {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -20px;
  padding: 10px;
  background-color: rgb(0 0 0 / 85%);
  height: 110px; /* Match the height of the navbar */
}

.search-input {
  padding: 5px;
  width: 90%;
  font-size: 16px;
  color: white;
  background-color: rgb(0 0 0 / 85%);
  border: 0px solid rgb(0 0 0 / 85%);
}

.close-search {
  font-size: 20px;
  margin-left: 100px;
  margin-right: 30px;
  cursor: pointer;
  color: white;
}

* {
  scroll-behavior: smooth;
}

.App-header.search-open .navbar {
  display: none;
}

.App-header.search-open .search-form {
  display: flex;
}





.get-in-touch {
  background-color: #61dafb;
  position: absolute;
  right: 0;
  margin-right: 30px;
  top: 3rem;
  border: none;
  color: black;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 100px;
  font-size: clamp(.7rem, 1.2vw, 3rem);
  margin-top: -10px;
  z-index: 1;
}

.get-in-touch:hover {
  background-color: #36c4ce;
  text-decoration: underline;
}



.menu-item {
  position: relative;
  font-size: clamp(.8rem, 1.2vw, 3rem);
  text-indent: -30px;
  margin-top: 20px;
}

.menu-item1 {
  position: relative;
  font-size: clamp(.7rem, 1.2vw, 3rem);
  margin-top: 20px;
}

.menu-item:hover .submenu {
  display: block;
}

.menu {
  display: flex;
  list-style-type: none;
}

.menu li {
  padding: 15px;
  cursor: pointer;
  position: relative;
}






.menuitemc {
  position: relative;
  font-size: clamp(.8rem, 1.2vw, 3rem);
  text-indent: -30px;
  margin-top: 20px;
  margin-right: 30px;
}




.menuiteml {
  position: relative;
  font-size: clamp(.8rem, 1.2vw, 3rem);
  text-indent: -30px;
  margin-top: 20px;
  margin-right: 170px;
}





/* Add the following CSS to App.css */

.contact-form1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 80px;
  margin-bottom: -150px;
}

.form1-group {
  margin-bottom: 15px;
  margin-left: 50px;
  width: 50%;
}

.form1-group label {
  display: block;
  color: rgb(0, 0, 0);
  margin-bottom: 5px;
  text-align: left;
}

.form1-group input,
.form1-group textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #007bff;
  margin-left: 600px;
  color: white;
  border: none;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #61dafb;
}

.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  margin-left: 600px;
  color: white;
  border: none;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #61dafb;
}



@media (min-width: 1745px) {
  .menuitemc {
    margin-right: 50px;
  }
  
  .menuiteml {
    margin-right: 250px;
  }  
  .get-in-touch {
    
    margin-right: 50px;
  }

.App-header {
  height: 10%;
}

.navbar {
  height: 15%;
}
}



/* Tablet and small desktop screens */
@media (max-width: 1279px) {
  .menu-item,
  .menuitemc,
  .menuiteml {
    margin-right: 10px;
  }

  .menu-icon {
    display: block;
  }

  .navbar .menu-items {
    display: none;
  }

  .navbar.open .menu-items {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px; /* Below the fixed navbar */
    left: 0;
    width: 100%;
    height: calc(100vh - 80px); /* Full height minus navbar */
    background-color: #000000;
    overflow-y: auto; /* Scrollable */
  }



  
  ul.menu {
    display: flex;
    flex-direction: column;
  }

  .get-in-touch {
    margin-right: 30px;
    margin-top: -10px;
  }
}

/* Mobile screens */
@media (max-width: 769px) {
  .App-header {
    height: 70px;
  }

  .navbar {
    height: 70px;
  }

  .logo-image img {
    max-height: 50px;
    margin-left: 10px;
  }

  .get-in-touch {
    margin-right: 20px;
    margin-top: -10px;
  }
}

/* Very small mobile screens */
@media (max-width: 400px) {
  .App-header {
    height: 60px;
  }

  .navbar {
    height: 60px;
  }

  .logo-image img {
    max-height: 40px;
  }

  .get-in-touch {
    margin-right: 10px;
    margin-top: -10px;
  }
}

@media (max-width: 316px) {
  .logo-image {
    width: 80%;
  }

  .get-in-touch {
    margin-right: 5px;
  }
}