.section {
    background-color: rgb(255, 255, 255);
    position: relative;
    text-align: left;
    color: white;
    margin-top: 100px;
  }
  
.contact {
    display: flex;
    margin-left: 300px;
    text-align: left;
    color: white;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  


  .section-image {
    margin-top: -100px;
    width: 100%;
  }
  /* Add the following CSS to App.css */
  
  .contactus {
    display: flex;
    align-items: flex-start;
  }
  .contactus h1 {
    display: flex;
    color: #2A2A2A;
    margin-top: 100px;
    margin-left: 50px;
    margin-right: 50px;
    font-size: 42px;
    max-width: 45%;
    align-items: flex-start;
    font-weight: 100;
    line-height: 1.5;
  }
  
  
  .contact-form {
    display: flex;
    margin-left: 50px;
    margin-right: -300px;
    margin-top: 50px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .form-group {
    margin-bottom: 15px;
    margin-left: 50px;
    width: 50%;
  }
  
  .form-group label {
    display: block;
    color: rgb(0, 0, 0);
    margin-bottom: 10px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 15px;
    border: none;
    border-radius: initial;
    border-bottom: 1px solid #ccc;
  }
  
  .submits-btn {
    border-radius: 100px;
    color: #007bff;
    border: 1px solid #007bff;
    /* margin-right: 0px; */
    margin-left: 50px;
    margin-bottom: 30px;
    cursor: pointer;
    background: transparent;
    align-items: center;
    height: 50px;
    width: 50%;
  }
  
  .submits-btn:hover {
    background-color: #007bff;
    color: #ffffff;
  }
  


  @media (max-width: 980px) {
    .contactus {
      display: contents;
    }
    .contact-form {
      margin-left: 20px;
    }
    .contactus h1 {
      margin-top: 50px;
      font-size: 32px;
      margin-right: 0;
      max-width: 100%;
    }
  }


  @media (max-width: 765px) {
    
    .contactus h1 {
      font-size: 22px;
    }
  }