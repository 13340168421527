

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
}

.overlay-content {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 60px;
}

h2.overlay-display {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #00c5d3;
    display: block;
    transition: 0.3s;
}

.overlay a:hover {
    color: #ffffff;
}
.overlay-display{
    color: #ffffff;
}
.overlay a:focus {
    color: #ffffff;
}


.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    margin-bottom: 1rem;
    font-size: 60px;
}

.tab-content {
    margin-inline: 3rem;
    letter-spacing: 0rem;
    font-size: clamp(1rem, 2vw, 3rem);
    text-align: justify;
    padding: 80px 20px;
}

@media (max-width: 1250px) {
    

.tab-content {
    margin-inline: 1rem;
    padding: 50px 20px;
}
}
@media (max-width: 600px) {
    .overlay-content h2 {
        font-size: 28px;
    }
}


@media screen and (max-height: 450px) {
    
    .overlay a {
        font-size: 20px
    }

    .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 35px;
    }
    
}


.more {
    margin-left: 50px;
    color: #00c5d3;
}


.more:hover {
   text-decoration: underline;
}


.nav-tab{
    color: #ffffff;
    font-size: clamp(.75rem, 1.1vw, 2rem);
    font-weight: 500;
}

.nav-tabs .nav-link {
    border: none;
}


.nav-tabs .nav-link.active {
    color: #00c5d3;
    background-color: transparent;
    border: none;
    border-color: rgba(0, 0, 0, 0);
}
.nav-tabs .nav-link:hover{
    border: none;
    border-color: rgba(0, 0, 0, 0);
    /* color: #00c5d3; */
}
