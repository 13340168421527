.App {
  text-align: left;
  width: 100%;
  max-width: 100%; /* Ensure .App stretches to full width */
  margin: 0; /* Remove auto centering */
  flex-grow: 1; /* Ensures that the .App takes up available space */
}



.footer {
  background-color: #202022;
  padding: 40px 20px;
  height: auto;
}

.footer p {
    /* background-color: #202022; */
    color: white;
  }
  

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #ffffff; /* Bottom border for the footer content */
  padding-bottom: 20px; /* Padding to separate the content from the bottom border */
  margin-top: 180px;
  margin-bottom: 10px; /* Margin to separate the content from the bottom part of the footer */
}

.footer-logo {
  flex: 1;
  margin-top: -50px;
  margin-right: -100px;
  margin-left: 110px;
}

.footer-logo img {
  max-width: 350px;
  margin-top: -60px;
}

.footer-logo p {
  margin-top: 40px;
}

.footer-links {
  display: flex;
  flex: 2;
  margin-right: -50px;
  justify-content: space-between;
}



.footer-column1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: -80px;
  margin-left: 100px;
  margin-bottom: 50px;
  border-left: 1px solid #ffffff;
}



.footer-column1 a {
  margin-right: 100px;
}



.footer-column1 ul {
  list-style: none;
  padding: 0;
  margin-left: -50px;
}

.footer-column1 ul li {
  margin-bottom: 20px;
}

.footer-column1 ul li a {
  color: white;
  margin-left: 100px;
  text-decoration: none;
}

.footer-column1 ul li a:hover {
  text-decoration: underline;
}
 







.footer-column2 {
  margin-top: -80px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: -200px;
}





.footer-column2 a {
  margin-right: -120px;
}



.footer-column2 ul {
  list-style: none;
  padding: 0;
}

.footer-column2 ul li {
  margin-bottom: 20px;
}

.footer-column2 ul li a {
  color: white;
  margin-left: 100px;
  text-decoration: none;
}

.footer-column2 ul li a:hover {
  text-decoration: underline;
}







.footer-column3 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: -80px;
  margin-left: 100px;
  margin-bottom: 50px;
  border-left: 1px solid #ffffff;
}



.footer-column3 a {
  margin-right: 100px;
}



.footer-column3 ul {
  list-style: none;
  padding: 0;
  margin-left: -50px;
}

.footer-column3 ul li {
  margin-bottom: 20px;
}

.footer-column3 ul li a {
  color: white;
  margin-left: 100px;
  margin-right: 250px;
  text-decoration: none;
}

.footer-column3 ul li a:hover {
  text-decoration: underline;
}








.footer-contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 0px;
}



.footer-contact p {
  margin-inline: 100px;
}



@media (max-width: 1190px) {
.footer-contact p {
  margin-inline: 50px;
}
}

/* Media Query for Tablets and Smaller Screens */
@media (max-width: 1121px) {
.footer {
  padding: 10px 40px;
  height: auto;
}
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
  }

  .footer-logo {
    margin: 0 auto;
    margin-top: -150px;
    text-align: center;
    width: 100%;
  }

  
.footer-logo img {
  width: 100%;
  margin-top: 0px;
}

.footer-logo p {
  width: auto;
  margin-top: 0px;
}

  .footer-links {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    margin-top: 20px;
    width: 90%;  /* make it 100% */
  }

  .footer-column1 {
    margin: 0;
    margin-right: 0px;
    margin-top: 50px;
    width: 100%;
    height: 100%;
    border-left: none;
    border-top: 1px solid #ffffff;
  }

  .footer-column2 {
    margin: 0;
    margin-left: 0px;
    margin-top: -192px;
    width: 100%;
    height: 100%;
    border-left: none;
    border-top: 0px solid #ffffff;
  }


  .footer-column3 {
    margin: 0;
    width: 100%;
    height: 100%;
    border-left: none;
    border-top: 1px solid #ffffff;
  }

  .footer-column1 ul {
    margin-top: 3%;
    margin-left: -300px;    
    padding: 0;
  }

  
  .footer-column2 ul {
    margin-left: 300px;
    padding: 0;
  }

  
  .footer-column3 ul {
    margin-left: 200px;
    margin-top: 4%;
    margin-bottom: -10px;
    padding: 0;
  }

  .footer-column1 ul li a,
  .footer-column2 ul li a,
  .footer-column3 ul li a {
    margin-left: 0;
    text-align: center;
  }
  
.footer-contact p {
  margin-inline: 20px;
}

}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo {
    text-align: center;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }
  .footer-column2{
    border-top: 1px solid #ffffff;
  }
  .footer-column1,
  .footer-column2,
  .footer-column3 {
    margin: 0px 0;
    text-align: center;
    border-left: none;
  }

  .footer-column2 ul {
    margin-top: 3%;
  }


  .footer-column1 ul,
  .footer-column2 ul,
  .footer-column3 ul {
    margin-left: 0;
    padding: 0;
  }

  .footer-column1 ul li,
  .footer-column2 ul li,
  .footer-column3 ul li {
    margin-bottom: 10px;
  }

  .footer-column1 ul li a,
  .footer-column2 ul li a,
  .footer-column3 ul li a {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }

  .footer-contact {
    flex-direction: column;
    text-align: center;
  }
}


@media (max-width: 300px) {

.footer-contact p {
  margin-inline: 10px;
}
}