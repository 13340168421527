/* Styled Border Box Animation */

@property --gradient-angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

:root {
    --clr-1: #052b2f;
    --clr-2: #000000;
    --clr-3: #0e4b50;
    --clr-4: #2d8f85;
    --clr-6: #1ac966;
    --clr-5: lime;
}

#sapacong {
    height: 10rem;
    background-image: linear-gradient(to bottom, #0e1538, #073438);
}

#border-card-container {
    background-color: var(--clr-2);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* // display: grid;
    // place-content: center; */
}

.card {
    height: 60vh;
    aspect-ratio: 1 / 1.5;
    background: var(--clr-1);
    border-radius: 0.5rem;

    position: relative;
}

#isbtnkidiv {
    background-color: #052b2f;
}

.card::before,
.card::after {
    content: "";
    position: absolute;
    inset: -0.5rem;
    z-index: -1;
    background: conic-gradient(from var(--gradient-angle),
            var(--clr-3),
            var(--clr-4),
            var(--clr-6),
            var(--maincolor1),
            var(--clr-6),
            var(--clr-4),
            var(--clr-3));
    border-radius: inherit;
    animation: rotation 1.5s linear 1;
}

.card::after {
    filter: blur(0.8rem);
}

.card{
    --gradient-angle: 0deg;
    transition: all 1s;
}
.card:hover{
    --gradient-angle: 360deg;
}

@keyframes rotation {
    0% {
        --gradient-angle: 0deg;
    }

    100% {
        --gradient-angle: 360deg;
    }
}