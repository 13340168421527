#aitechnologies {
    padding: 50px 20px;
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    margin-bottom: -50px;
  }
  
#aitechnologies h1 {
    color: #000000; /* Change this to your preferred color for headings */
    text-align: left;
    font-size: 42px;
    font-weight: 400;
    margin-left: 10px;
    margin-bottom: 30px;
    /* margin-top: -50px; */
  }
  
  #aitechnologies  h2 {
    /* display: inline-block; */
    color: #999; /* Change this to your preferred color for subheadings */
    line-height: 25px;
    font-size: 20px;
    margin-bottom: 20px;
    white-space: pre-line;
    text-align: left;
    text-transform: uppercase;
    padding: 1px 10px;
    display: inline-block;
    background-color: #ffffff;
  }
  

  .aitech-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* margin-right: 100px; */
    margin-top: -30px;
    border-top: 1px solid #999;
  }
  
   
  .aitech-icon {
    padding: 50px 10px;
    width: 180px;
    height: 180px;
    object-fit: scale-down;

  }
  


  @media (max-width: 300px) {
    
#aitechnologies h1 {
    font-size: 32px;
}
    
#aitechnologies h2 {
    font-size: 18px;
}
  }
  