
.get-in-touch-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: -150px;
  }
  
  .get-in-touch-form-group {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 20px;
    width: 95%;
  }

  .getintouch-outcanvas {
    border-radius: 360px;
    background-color: #26292d;
    color: white;
    border: none;
}

.getintouch-outcanvas:focus {
    background-color: white;
    color: #0b0c0d;
    box-shadow: none;
}

.getintouch-outcanvas:focus::placeholder {
    color: black;
}

.getintouch-outcanvas::placeholder {
    color: white;
}


#offCanvasRow {
    display: flex;
    flex-direction: row;
}

.Offcanvas {
    font-weight: 400;
    animation: fadeIn 0.5s ease-in-out;
}

#container {
    text-align: center;
}

@keyframes anime {
    from {
        transform: translateY(60px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

#offCanvasHeading {
    animation: anime 0.8s ease-in-out;
}

#formGridState.form-check-input {
    cursor: pointer;
}



#checkbtn {
    background-color: #26292d;
    color: white;
}

#activeButton {
    background-color: white;
    color: black;
}

#checkbtn,
#activeButton {
    cursor: pointer;
    /* padding: 0.5rem; */
    border-radius: 360px;
    border: 1px solid white;
    font-weight: 700;
    row-gap: 1rem;
}



  
.get-in-touch-form-group-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 97%;
    margin-left: 0px;
  }
  
  .get-in-touch-form-group-row .get-in-touch-form-group {
    width: 48%; /* Adjust width to ensure the fields fit side-by-side */
  }

  
  .get-in-touch-form-submit-btn {
    background-color: #61dafb;
    border: none;
    color: black;
    display: flex;
    padding: 10px 20px;
    margin-left: 20px;
    margin-bottom: -5px;
    align-items: center;
    cursor: pointer;
    height: 50px;
    width: 95%;
    flex-direction: column;
  }
  
  
  .get-in-touch-form-submit-btn:hover {
    background-color: #00c5d3;
  }
  




  
.getintouch-form {
    position: fixed;
    top: 0;
    right: -100%; /* Ensure it's completely off-screen */
    width: 36rem;
    height: 100%;
    background-color: rgb(11 12 13);
    /* box-shadow: -2px 0 5px rgba(0,0,0,0.5); */
    transition: right 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    padding: 20px;
    z-index: 1000; /* Ensure it appears above other elements */
  }
  
  .getintouch-form.open {
    right: 0; /* Slide in when active */
    /* width: 580px; */
  }
  
  .getintouch-form-content {
    flex-grow: 1;
    color: #ffffff;
  }
  
  .getintouchclose-form {
    align-self: flex-end;
    cursor: pointer;
    font-size: 24px; /* Larger size for the close button */
    color: rgb(255, 255, 255);
    z-index: 1000;
  }
  
  .getintouch-form h2 {
    margin-bottom: 20px;
    font-size: clamp(1.5rem, 2.5vw, 3rem);
  }
  
  .getintouch-form p {
    font-size: clamp(1rem, 1.3vw, 3rem);
    margin-bottom: 20px;
  }
  
  .interests {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .getintouch-form input[type="text"],
  .getintouch-form input[type="email"],
  .getintouch-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .getintouch-form button {
    background-color: #61dafb;
    border: none;
    color: black;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  
  /* Ensure the form remains hidden off-screen when not active */
  .getintouch-form {
    right: -500%;
  }
  
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .getintouch-form {
      width: 100%; /* Full width on small screens */
      height: 100%;
    }
  }
  
  
  
.get-in-touch-form-content {
    flex-grow: 1;
    color: #ffffff;
  }
  
  .get-in-touch-close-form {
    align-self: flex-end;
    cursor: pointer;
    font-size: 24px; /* Larger size for the close button */
    color: rgb(255, 255, 255);
    z-index: 1000;
  }
  
  .get-in-touch-form h2 {
    margin-bottom: 20px;
  }
  
  .get-in-touch-form p {
    margin-bottom: 20px;
  }
  
  .interests {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .get-in-touch-form input[type="text"]
   {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 0px solid #ccc;
    border-radius: 100px;
  }
  .get-in-touch-form input[type="email"]
  {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 0px solid #ccc;
    border-radius: 100px;
  }
  .get-in-touch-form input[type="tel"]
   {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 0px solid #ccc;
    border-radius: 100px;
  }
  
  .get-in-touch-form textarea
   {
    width: 100%;
    height: 100px;
    padding: 10px;
    margin-bottom: 20px;
    border: 0px solid #ccc;
    border-radius: 100px;
  }
  
  
  .get-in-touch-form button {
    background-color: #61dafb;
    border: none;
    color: black;
    padding: 10px;
    cursor: pointer;
    border-radius: 100px;
    font-size: clamp(1.3rem, 1.4vw, 3rem);
  }
  
  
  
  
  
  /* Add the following CSS to App.css */
  
  

  
  .get-in-touch-form-group label {
    display: block;
    color: rgb(0, 0, 0);
    margin-bottom: 5px;
    text-align: left;
  }
  
  .get-in-touch-form-group input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  .get-in-touch-form-group textarea {
    width: 100%;
    height: 50px;
    padding: 10px;
    box-sizing: border-box;
  }
  
  
  
  .get-in-touch-form-group input::placeholder,
  .get-in-touch-form-group textarea::placeholder {
    color: #999; /* Light gray color for placeholders */
  }
  
  
  
  
  
  
  
  
  @media (max-width: 768px) {
    /* Stack form elements vertically */
    .get-in-touch-form-group-row {
        flex-direction: column;
        width: 100%;
    }
  
    .get-in-touch-form-group-row .get-in-touch-form-group {
        width: 100%;
        margin-bottom: 15px; /* Add space between stacked elements */
    }
  
    .get-in-touch-form-content {
        text-align: center;
    }
  
    .get-in-touch-form-group {
        margin-left: 0;
        width: 100%;
    }
  
    .get-in-touch-form-submit-btn {
        width: 100%;
        margin-left: 0;
    }
  
    .get-in-touch-close-form {
        font-size: 20px; /* Adjust close button size for smaller screens */
    }
  
  }
  
  @media (max-width: 576px) {
    /* Further adjustments for very small screens */
    .get-in-touch-form {
        margin-bottom: -50px; /* Adjust bottom margin */
    }
  
    .get-in-touch-form input[type="text"],
    .get-in-touch-form input[type="email"],
    .get-in-touch-form input[type="tel"],
    .get-in-touch-form textarea {
        padding: 8px; /* Adjust padding */
    }
  
    .get-in-touch-form button {
        padding: 8px 16px; /* Adjust button padding */
    }
  
    .get-in-touch-close-form {
        font-size: 18px; /* Further adjust close button size */
    }
  
  
  }

  
