#company {
    padding: 100px 20px;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    background-color: #000000;
    margin-bottom: -50px;
  }
  
#company h1 {
    color: #00c5d3; /* Change this to your preferred color for headings */
    text-align: left;
    font-size: 42px;
    font-weight: 400;
    margin-left: 50px;
    margin-bottom: 30px;
    margin-top: -50px;
  }
  
  #company p {
    color: #ffffff; /* Change this to your preferred color for subheadings */
    line-height: 25px;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: .5px;
    margin-left: 50px;
    margin-bottom: 20px;
    max-width: 85%;
    white-space: pre-line;
    text-align: justify;
  }
  
  #company p span{
    font-weight: bold;
  }
  



@media (max-width: 500px) {
#company h1 {
    margin-left: 10px;
  }
  
  #company p {
    margin-left: 10px;
    margin-bottom: 20px;
    max-width: 100%;
  }
  
}