.accordion {
    --bs-accordion-color: rgb(255, 255, 255);
    --bs-accordion-bg: rgba(0, 0, 0, 0);

    /* --bs-accordion-active-color: rgb(255, 0, 0);
    --bs-accordion-active-bg: rgb(79, 0, 95); */
}


.accordion-button{
    background-color: rgba(0, 0, 0, 0.9);
    color: #ffffff;
}

.accordion-button:not(.collapsed) {
    color: #61dafb;
    background-color: #000000;
}

.accordion-header{
    margin-left: 0px;
    width: 100%;
}
.accordion-button::after{
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    /* background-color: white; */
    color: white;
}
/* .accordion{
    --bs-accordion-btn-icon: url(data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%2016'%20fill%3D'none'%20stroke%3D'%23052c65'%20stroke-linecap%3D'round'%20stroke-linejoin%3D'round'%3E%3Cpath%20d%3D'M2%205L8%2011L14%205'%2F%3E%3C%2Fsvg%3E)
    --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%2016'%20fill%3D'none'%20stroke%3D'%23212529'%20stroke-linecap%3D'round'%20stroke-linejoin%3D'round'%3E%3Cpath%20d%3D'M2%205L8%2011L14%205'%2F%3E%3C%2Fsvg%3E)
} */


@media only screen and (min-width: 700px){
    .accordion{
        display: none;
    }
    .Wo{
        display: block;
    }
}
@media only screen and (max-width: 1091px){
    .accordion{
        display: block;
    }
    .Wo{
        display: none;
    }
}


@media (max-width: 340px) {
    .accordion-item h2 {
        font-size: 8px;
    }
}