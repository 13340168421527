#Dada {
background-color: #000;
padding: 5rem 5rem;
padding-inline: 3rem;
margin-top: -50px;
}


#Hover-Tab {
  color: #ffffff;
  border: 1px solid;
  border-image-slice: 1;
  margin: 1rem 0;
  width: 100%;
  padding: 1rem;
  border-radius: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#Hover-Tab p {
  margin: 0;
}

#Hover-Tab:hover {
  color: #000;
}
#containers{
  display: flex;
  flex-direction: row;
}
#Tabs-container {
  font-size: clamp(.7rem, 1.5vw, 3rem);
  font-weight: 300;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

#TabsOutput-container {
  width: 100%;
  background-color: #434343;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border-radius: 25px;
  padding: 20px 20px 0px 20px;
  margin-left: 10%;
}
/* 
.TabsOutput-container {
  text-align: justify;
  padding: 20px;
  margin-bottom: -50px;
  margin-top: 0px;
  width: 50%;

  display: flex;
    flex-direction: column;
    align-items: center;
    height: 250px;
    background: rgba(0, 0, 0, 0.829);
} */

@keyframes fade {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

.TabsOutput-container img {
  max-width: 100px;
  margin-bottom: 10px;
}

.TabsOut_ANI{
  animation: fade 3s ease-in-out;
}

.TabsOutput-container h1 {
  margin: 10px 0;
  color: #61dafb;
  font-size: clamp(1rem, 2.5vw, 3rem);
  font-weight: 700;
  line-height: 1;
  margin-bottom: 24px;
}

.TabsOutput-container h3 {
  font-size: clamp(.8rem, 1.2vw, 2.5rem);
  color: #ffffff;
  font-weight: normal;
  text-align: justify;
}



@media (max-width: 600px) {
  #Dada {
    padding: 2rem 2rem;
    padding-inline: 1rem;
    margin-top: -50px;
    }
    
  #containers{
    flex-direction: column;
  }
  #Tabs-container {
    flex-direction: row;
  }
  #Hover-Tab {
    width: 45%;
    margin: .5rem 0;
    border-radius: 20px;
  }
  #TabsOutput-container {
    margin-inline: auto;
  }
}


@media (max-width: 336px) {
#Hover-Tab {
  padding: .3rem;
}
#Hover-Tab p {
  font-size: 9.5px;
}
}
