#careers {
  padding: 100px 20px;
  width: 100%;
  background-color: #000;
  box-sizing: border-box;
}



#careers h1 {
  color: #00c5d3;
  /* Change this to your preferred color for headings */
  text-align: left;
  font-size: 42px;
  margin-left: 50px;
  font-weight: 400;
}

#careers p {
  color: #ffffff;
  /* Change this to your preferred color for subheadings */
  line-height: 25px;
  font-size: 18px;
  margin-left: 50px;
  white-space: pre-line;
  text-align: left;
  font-weight: 350;
}

#isbtnkidiv{
  height: 150px;
  width: 280px;
}


.career {
  margin-top: 0px;
  max-width: 12000px;
  background-color: #000;
  padding: 20px;
  margin-bottom: -100px;
}

.job-listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.job {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  height: 150px;
  width: 280px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

/* .job:hover {
    box-shadow: 0.7rem 0.7rem 0.7rem rgba(255, 255, 255, 0.2), -0.1rem -0.1rem 0.1rem rgb(0, 0, 0);
  } */

.job h3 {
  margin-top: 0;
  font-size: 24px;
  text-align: left;
  color: #000;
  font-weight: 600;
}

.job h3:hover {
  text-decoration: underline;
  cursor: pointer;
}

.job p1 {
  display: flex;
  margin-bottom: 15px;
  font-size: 15px;
  text-align: justify;
  color: #000;
}

.job-icon {
  width: 50px;
  height: 50px;
  fill: #333;
  /* Adjust color as needed */
}


.job-apply-now {
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 10px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  color: #0045a6;
  cursor: pointer;
}



@media (max-width: 330px) {

  
.career {
  padding: 0px;
  margin-bottom: -10px;
}

  .job {
    height: 180px;
    margin-left: -10px;
  }


  #isbtnkidiv{
    height: 130px;
    width: 250px;
  }
  

  #careers h1 {
    margin-left: 20px;
  }

  #careers p {
    margin-left: 20px;
  }

}


@media (max-width: 272px) {

  #isbtnkidiv{
    height: 120px;
    width: 230px;
  }
  .job-listing {
    margin-left: -10px;
  }
 
}

/* #isbtnkidiv a:hover {
  text-decoration: underline;
} */

#applyNowbtn-Container {
  background-color: transparent;
  width: fit-content;
}

#poochakadanda {
  position: relative;
  width: 100%;
  height: 10px;
  background-color: transparent;
}

#poocha {
  width: 0%;
  bottom: 1rem;
  position: absolute;
  transition: all 1s;
  margin-top: 0;
}

#isbtnkidiv:hover #poocha {
  width: 65%;
}