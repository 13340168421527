
#ouraicapabilities {
    padding: 0px 20px;
    text-align: left;
    width: 100%;
    font-size: 32px;
    background-color: #00000000;
    color: #61dafb;
    box-sizing: border-box;
  }
  .ouraicapabilities {
    text-align: left;
    width: 100%;
    font-size: 32px;
    background-color: #00000000;
    color: #61dafb;
    box-sizing: border-box;
  }
  
  
  .ouraicapabilities h1 {
    padding-left: 20px;
    color: #ffffff;
    width: 100%;
    font-size: 42px;
    font-weight: 400;
    text-align: left;
    background-color: #00000000;
  }
  
  .ouraicapabilities p {
    padding-left: 20px;
    color: #ffffff;
    width: 80%;
    font-size: 18px;
    text-align: left;
  }



  

.ai-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 0px 20px;
  background-color: #000000;
  margin-top: -50px;
  height: auto;
}

.ai-grid h1 {
  color: #000000;
  width: 100%;
  font-size: 32px;
  text-align: left;
  /* margin-top: -50px; */
}
.ai-grid p {
  color: #000000;
  width: 100%;
  font-size: 32px;
  text-align: justify;
  /* margin-top: -50px; */
}


.ai-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 20px;
  height: auto;
  margin-top: -10px;
  background: rgba(0, 0, 0, 0.564);
  /* border: 1px solid #ddd; */
}

.ai-item img {
  max-width:200px;
  margin-bottom: 10px;
}

.ai-item h1 {
  margin: 10px 0;
  color: #61dafb;
  font-size: 26px;
}

.ai-item p {
  font-size: 16px;
  color: #fff;
  margin-bottom: -10px;
}



.expertise-icon {
  display: flex;
}

.expertise-icon svg {
  display: flex;
  width: 80%; /* Adjust size of the icon */
  height: 80px;
  margin-left: 0px;
  margin-right: 230px;
  fill: #ffffff; /* Adjust icon color */
}

.expertise-icons {
  display: flex;
  width: 40%;
  height: 40px;
  margin-left: 0px;
  margin-right: 300px;
}






