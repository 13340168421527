/* IndustryBox.css */

.industry-box {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin-left: -20px;
    border: 1px solid #ffffff33;
    border-radius: 0px;
}


.industry-box:hover {
    border: 1px solid #007bff;
  }

.icon {
    width: 60px;
    height: 60px;
    margin-top: -40px;
    background-color: rgb(0, 0, 0);
    margin-bottom: 10px;
}

.title {
    font-size: 18px;
    font-weight: 1;
    margin-top: -15px;
    margin-bottom: 20px;
    text-align: center;
    color: #ffffff;
    z-index: 1;
}


/* .title:hover {
    text-decoration: underline;
  } */



  
  @media (max-width: 740px) {
    .icon {
        width: 50px;
        height: 50px;
        margin-top: -30px;
    }    
  }
  
  @media (max-width: 600px) {
    .icon {
        width: 40px;
        height: 40px;
        margin-top: -25px;
    }
  }


  @media (max-width: 500px) {
    .icon {
        width: 35px;
        height: 35px;
        margin-top: -20px;
    }
  }
  