.jobapplication {
    background-color: rgb(255, 255, 255);
    position: relative;
    text-align: left;
   padding: 1px 1px;
  }



.job-application {
    align-items: flex-start;
  }


.job-application-form {
    padding: 20px;
    background-color: #e9e9e9;
    position: relative;
    text-align: left;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
  }
  

  
  
  .job-application h1 {
    color: #2A2A2A;
    margin-top: 150px;
    margin-left: 50px;
    font-size: 50px;
    align-items: flex-start;
  }

  .job-application-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  .job-application p {
    color: #2A2A2A;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
    font-size: 20px;
    align-items: flex-start;
  }
  

  
  .job-application-form div {
    margin-bottom: 15px;
  }
  
  .job-application-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .job-application-form input[type="text"],
  .job-application-form input[type="email"],
  .job-application-form input[type="tel"],
  .job-application-form input[type="url"],
  .job-application-form input[type="file"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .job-application-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .job-application-form button:hover {
    background-color: #0056b3;
  }
  


/* Ensure the navbar items are always visible */
@media (max-width: 290px) {
  .job-application h1 {
    font-size: 30px;
  }
}



  