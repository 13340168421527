@import url('@fortawesome/fontawesome-free/css/all.min.css');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.btnTrans{
  transition: all 1s;
}

html,
body {
  background-image: url('./assets/bg.png');
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  /* Prevent horizontal scroll */
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  text-align: center;
  width: 100%;
  max-width: 100%;
  /* Ensure .App stretches to full width */
  margin: 0;
  /* Remove auto centering */
  flex-grow: 1;
  /* Ensures that the .App takes up available space */
}

:root {
  --maincolor1: #00c5d3;
  --maincolor2: #61dafb;
}