#technologies {
    padding: 100px 20px;
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
  }
  
#technologies h1 {
    color: #000000; /* Change this to your preferred color for headings */
    text-align: left;
    font-size: 42px;
    margin-left: 50px;
    margin-bottom: 30px;
    margin-top: -50px;
    font-weight: 400;
  }
  
  #technologies  p {
    color: #000000; /* Change this to your preferred color for subheadings */
    line-height: 25px;
    font-size: 18px;
    margin-left: 50px;
    margin-bottom: 20px;
    white-space: pre-line;
    text-align: left;
  }
  

  .tech-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  /* .tech-icon-box {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  } */
  
  .tech-icon {
    width: 150px;
    height: 150px;
    margin: 20px;
    object-fit: contain;
  }
    


  @media (max-width: 440px) {
    
#technologies h1 {
  margin-left: 20px;
  font-size: 34px;
}

#technologies  p {
  margin-left: 20px;
  width: auto;
}
  }


  

  @media (max-width: 290px) {
    
    #technologies h1 {
      margin-left: 10px;
    }
    
    #technologies  p {
      margin-left: 10px;
    }
      }