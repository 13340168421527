
#cloudsolution {

    background-color: #169d4700;
  }

#cloudsolutions {
  display: flex;
    padding: 30px 30px;
    width: 100%;
    background-color: #000000;
    box-sizing: border-box;
    margin-bottom: -10px;
  }
#cloudsolutions1 {
    width: 140%;
    box-sizing: border-box;
    margin-right: 20px;
    margin-left: -20px;
  }

  

#cloudsolutions2 {  
    width: 100%;
    box-sizing: border-box;
    margin-left: 20px;
    margin-right: -20px;
  }
  
  
  
  #cloudsolutions1 h1 {
    color: #00c5d3;
    width: 110%;
    font-weight: 650;
    font-size: 48px;
    text-align: left;
    margin-top: 20px;
  }
  #cloudsolutions1 h1 span  {
    font-weight: 700; /* Bold weight for the highlighted text */
    /* background: linear-gradient(to top, #ffffff 25%, transparent 25%); */
    padding: 0 5px;
    color: #00c5d3; /* Ensures text color is black */
    background-size: 100% 100%;
    background-position: 0 30%; /* Adjust this value to move the highlight up or down */
    background-repeat: no-repeat;
  }
  
  #cloudsolutions1 h2 {
    color: #bbfaff;
    width: 100%;
    font-weight: 100;
    letter-spacing: 2px;
    font-size: 21px;
    text-align: left;
    margin-top: 20px;
  }
  
  
  #cloudsolutions2 p {
    color: #fbfbfb;
    width: 100%;
    font-size: 18px;
    text-align: justify;
    margin-top: 30px;
  }
  
  
#cloudservices {
  padding: 60px 20px;
  width: 100%;
  text-align: left;
  background-color: #ffffff00;
}
#cloudservices h1{
  color: #ffffff;
  font-size: 42px;
  font-weight: 400;
  margin-top: 100px;
}
#cloudservices p{
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 10px;
}

  


.cloudServices {
    padding: 10px 20px;
    text-align: left;
    width: 100%;
    margin-top: -70px;
    font-size: 32px;
    background-color: #000;
    color: #61dafb;
    box-sizing: border-box;
  }
  
  
  .cloudservices-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 0px 20px;
    background-color: #000000;
    margin-top: -50px;
    height: 620px;
    padding: 30px 10px;
  }
  
  .cloudservices-grid h1 {
    color: #ffffff;
    width: 100%;
    font-size: 32px;
    text-align: left;
    /* margin-top: -50px; */
  }
  .cloudservices-grid p {
    color: #ffffff;
    width: 100%;
    font-size: 32px;
    text-align: justify;
    /* margin-top: -50px; */
  }
  
  
  .cloudservices-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 250px;
    padding: 20px;
    margin-bottom: -50px;
    margin-top: 0px;
    background: rgba(0, 0, 0, 0.829);
    /* border: 1px solid #ddd; */
  }
  
  .cloudservices-item img {
    max-width:200px;
    margin-bottom: 10px;
  }
  
  .cloudservices-item h1 {
    margin: 10px 0;
    color: #61dafb;
    font-size: 26px;
  }
  
  .cloudservices-item p {
    font-size: 16px;
    color: #ffffff;
  }
  
  
  
  .cloudservices-icon {
    display: flex;
    margin-left: -30px;
  }
  
  .cloudservices-icon svg {
    display: flex;
    width: 50%; /* Adjust size of the icon */
    height: 50px;
    margin-left: 0px;
    margin-right: 230px;
    fill: #ffffffcf; /* Adjust icon color */
  }
  
  
  .cloudservices-icons {
    display: flex;
    width: 50%;
    height: 50px;
    margin-left: 0px;
    margin-right: 230px;
  }
  
  
  @media (max-width: 1200px) {
    .cloudservices-grid {
    height: 800px;
  }
}
  
  @media (max-width: 768px) {
    #cloudsolutions {
      flex-direction: column;
    }

    #cloudsolutions1 h1 {
      font-size: 35px;
      width: 500px;
    }
    #cloudsolutions1 h2 {
      font-size: 21px;
      width: 500px;
    }
    #cloudsolutions2 p {
      font-size: 18px;
      margin-left: -30px;
    }
    .cloudservices-item {
      margin-bottom: 10px;
    }
    .cloudservices-grid {
      height: auto;
    }
    }

    
    
  @media (max-width: 500px) {
    #cloudsolutions1 h1 {
      width: 400px;
    }
    #cloudsolutions1 h2 {
      width: 400px;
    }
    #cloudsolutions2 p {
      font-size: 18px;
      margin-left: -50px;
      width: auto;
    }
    
    }

    
    @media (max-width: 400px) {
      #cloudsolutions1 h1 {
        width: 300px;
      }
      #cloudsolutions1 h2 {
        width: 300px;
      }

      
      .cloudservices-item {
        height: auto;
      }
      .cloudservices-item p {
        width: 300px;
        margin-left: -20px;
      }
      }


      @media (max-width: 300px) {
      .cloudservices-item p {
        width: 200px;
        margin-left: -90px;
      }

      #cloudsolutions1 h1 {
        width: 200px;
      }
      #cloudsolutions1 h2 {
        width: 200px;
      }
    }