#industries {
    padding: 40px 20px;
    width: 100%;
    margin-bottom: -10px;
    background-color: #000;
    box-sizing: border-box;
  }
  
#industries h1 {
    color: #00c5d3; /* Change this to your preferred color for headings */
    text-align: left;
    font-size: 42px;
    font-weight: 400;
    margin-left: 50px;
    margin-bottom: 30px;
    margin-top: -50px;
  }
  
  
  .industry-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 740px) {
    .industry-boxes {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      padding: 0px 0px;
    }
  }

  
  @media (max-width: 600px) {
    .industry-boxes {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }

  
  @media (max-width: 500px) {
    .industry-boxes {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }
  
  @media (max-width: 300px) {
    
#industries h1 {
  margin-left: 20px;
}

  }