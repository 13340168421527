#platforms {
  padding: 50px 20px;
  background-color: #ffffff00;
  width: 100%;
  box-sizing: border-box;
}

#platforms h1 {
  color: #ffffff;
  width: 40%;
  font-size: 42px;
  font-weight: 400;
  text-align: left;
  display: inline-block;
  margin-top: 100px;
  margin-bottom: 70px;
}

/* #platforms h2 {
    width: 100%;
    font-size: 28px;
    text-align: left;
    margin-top: -50px;
    margin-bottom: 10px;
    margin-left: 40px;
    text-transform: uppercase;
  } */

#platforms h2 {
  color: #ffffff;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  margin-top: -60px;
  margin-bottom: 20px;
}



.platforms-grid {
  display: grid;
  background-color: #ffffff;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 50px 20px;
  margin-bottom: -50px;
}

.platform-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: auto;
  width: auto;
  background: rgb(255, 255, 255);
}

.platform-item img {
  max-width: 400px;
  max-height: 200px;
  margin-bottom: 30px;
  block-size: 100px;
  margin-top: 30px;
}


.platform-item p {
  padding: 10px 25px;
  font-size: 16px;
  text-align: justify;
  /* margin-bottom: 20px; */
  margin-left: 20px;
  margin-right: 20px;
}







#aiplatforms {
  padding: 50px 20px;
  background-color: #ffffff00;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: -30px;
}

/* #aiplatforms h2 {
  width: 100%;
  font-size: 28px;
  text-align: left;
  margin-top: -50px;
  margin-bottom: 10px;
  margin-left: 40px;
  text-transform: uppercase;
} */
#aiplatforms h2 {
  color: #ffffff;
  width: 100%;
  font-size: 18px;
  text-align: left;
  margin-top: -60px;
  margin-bottom: 10px;
}



.aiplatforms-grid {
  padding: 10px 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
  background-color: #ffffff;
  margin-bottom: -50px;
  margin-left: -20px;
  margin-right: -20px;
}

.aiplatforms-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: auto;
  width: auto;
  background-color: rgb(255, 255, 255);
}

.aiplatforms-item img {
  max-width: 400px;
  max-height: 200px;
  margin-bottom: 30px;
  block-size: 100px;
  margin-top: 30px;
}

.aiplatforms-item h3 {
  font-size: 28px;
  margin-top: -50px;
  text-align: right;
}

.aiplatforms-item p {
  padding: 10px 25px;
  font-size: 16px;
  text-align: justify;
  /* margin-bottom: 20px; */
  margin-left: 20px;
  margin-right: 20px;
}


@media (max-width: 340px) {

.platform-item {
  width: 300px;
}
.aiplatforms-item {
  width: 300px;
}
  
.platform-item p {
  padding: 10px 35px;
  margin-left: -10px;
  margin-right: 20px;
}
.aiplatforms-item p {
  padding: 10px 35px;
  margin-left: -10px;
  margin-right: 20px;
}
#aiplatforms h1 {
  margin-left: -20px;
}

#aiplatforms h2 {
  margin-left: -20px;
}

}



@media (max-width: 260px) {

  .platform-item {
    width: 200px;
  }
  
.aiplatforms-item {
  width: 200px;
}
.platform-item p {
  padding: 10px 35px;
  margin-left: 0px;
  margin-right: 0px;
}
.aiplatforms-item p {
  padding: 10px 35px;
  margin-left: 0px;
  margin-right: 0px;
}



.aiplatforms-item img {
  max-width: 150px;
  max-height: 100px;
}
.platform-item img {
  max-width: 150px;
  max-height: 100px;
}


}