/* Container for Our Locations section */
#ourlocations {
  padding: 50px 20px;
  background-color: #cdcdcd;
  width: 100%;
  box-sizing: border-box;
}

/* Styles for main heading */
#ourlocations h1 {
  color: #0077ff;
  font-size: 32px;
  margin-top: -60px; /* Adjusted margin to reduce excessive spacing */
  margin-bottom: 30px; /* Adjusted margin for better spacing */
  text-align: left;
  text-transform: uppercase;
  display: inline-block;
}

/* Styles for subheadings */
#ourlocations h2 {
  width: 100%;
  color: #000000;
  font-size: 28px;
  text-align: left;
  margin-top: 20px;
  margin-left: 0px; /* Centered alignment */
  text-transform: uppercase;
}

/* Styles for paragraph text */
.ourlocations p {
  font-size: 16px;
  color: #707070;
  text-align: left;
  margin: 10px 0; /* Consistent margin for better spacing */
  margin-right: 50px; /* Adjusted margin for better alignment */
  width: 100%;
  padding-left: 30px; /* Added padding for consistency */
}

/* Grid layout for locations */
.ourlocations-grid {
  padding: 10px 20px;
  padding-top: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px; /* Reduced gap for more compact design */
  background-color: #ffffff;
  margin-top: 50px; /* Adjusted margin for better spacing */
}

/* Styles for individual location items */
.ourlocations-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px; /* Added padding for spacing */
  background-color: #ffffff; /* Light background color for contrast */
  width: auto;
}

/* Styles for location images */
.ourlocations-item img {
  max-width: 100%; /* Ensure images are responsive */
  height: auto; /* Maintain aspect ratio */
  margin-top: -30px; /* Adjusted margin for better alignment */
}

/* Responsive adjustments */
@media (max-width: 820px) {
  #ourlocations h1 {
    font-size: 28px; /* Reduced font size for smaller screens */
  }

  #ourlocations h2 {
    font-size: 24px; /* Reduced font size for smaller screens */
    margin-left: 0px; /* Adjusted margin for better alignment */
  }

  .ourlocations-item img {
    margin-top: 0; /* Removed negative margin on smaller screens */
  }

  .ourlocations p {
    margin-left: 0px;
  }
}




/* Responsive adjustments */
@media (max-width: 810px) {

  #ourlocations h2 {
    padding-left: 70px;
  }

  .ourlocations p {
    padding-left: 100px;
  }
}


/* Responsive adjustments */
@media (max-width: 796px) {

  #ourlocations h2 {
    padding-left: 70px;
  }

  .ourlocations p {
    padding-left: 100px;
  }
}



/* Responsive adjustments */
@media (max-width: 755px) {

  #ourlocations h2 {
    padding-left: 50px;
  }

  .ourlocations p {
    padding-left: 75px;
  }
}

/* Responsive adjustments */
@media (max-width: 666px) {

  #ourlocations h2 {
    padding-left: 0px;
  }

  .ourlocations p {
    padding-left: 30px;
  }
}



/* Responsive adjustments */
@media (max-width: 430px) {
  .ourlocations-item {
    width: 90%;
  }
}

/* Responsive adjustments */
@media (max-width: 395px) {
  .ourlocations-item {
    width: 80%;
  }
}

/* Responsive adjustments */
@media (max-width: 350px) {
  .ourlocations-item {
    width: 70%;
  }
}

/* Responsive adjustments */
@media (max-width: 300px) {
  .ourlocations-item {
    width: 60%;
  }
}

/* Responsive adjustments */
@media (max-width: 275px) {
  .ourlocations-item {
    width: 50%;
  }
}

/* Responsive adjustments */
@media (max-width: 237px) {
  .ourlocations-item {
    width: 40%;
  }
}